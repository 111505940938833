import Styled from 'styled-components';
import { Form as AntForm } from 'antd';

const Container = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  background-color: #f9f9f9; 
  min-height: 100vh;
`;

const Heading = Styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0;
  color: #333;
`;

const SubHeading = Styled.p`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 40px;
  color: black;
  width: 90%;
  max-width: 500px;
  text-align: center;
`;

const StyledForm = Styled(AntForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 750px;
  background: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  .ant-form-item {
    margin-bottom: 15px;
  }

  label {
    font-weight: 600;
    margin-bottom: 4px;
  }
`;

const ButtonWrapper = Styled.div`
  text-align: right;
`;

export { Container, Heading, SubHeading, StyledForm, ButtonWrapper };
