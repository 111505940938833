import React, { useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from "react-hot-loader/root";
import { Provider, useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ConfigProvider, Spin } from "antd";
import "./static/css/style.css";
import "react-toastify/dist/ReactToastify.css";
import config from "./config/config";
import "antd/dist/antd.less";
import Dashboard from "./container/dashboard";
import { ToastContainer } from "react-toastify";
import store from "./redux/store.js";
import ContactUs from "./container/contact-us";
import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import OktaProvider from "./providers/OktaProvider.js";
import { Adminlogin, fetchDahboard } from "./config/api/thunkapi.js";
import { useHistory } from "react-router-dom";
import SignIn from "./container/profile/authentication/overview/SignIn.js";
import AuthLayout from "./container/profile/authentication/Index";
import withAdminLayout from "./layout/withAdminLayout.js";
import Cookies from "js-cookie";
import { apiDashboardCallSuccess } from "./redux/dashboard/dashboardReducer.js";
import { setViewsResults, setWorkbooks } from "./redux/sidebar/sidebar.js";

const { theme } = config;

function ProviderConfig() {
  const { authState } = useOktaAuth();

  const { rtl, topMenu, darkMode } = useSelector((state) => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  return (
    <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Route
          path="/"
          exact
          component={
            authState?.isAuthenticated
              ? withAdminLayout(Dashboard)
              : AuthLayout(SignIn)
          }
        />
        <Route path="/login/callback" exact component={LoginCallback} />
        <Route path="/redirect" exact component={LoginWithApi} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/view/:id" component={withAdminLayout(Dashboard)} />
        <Route exact path="/social" component={withAdminLayout(Dashboard)} />
        <ToastContainer />
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <Router basename={process.env.PUBLIC_URL}>
        <OktaProvider>
          <ProviderConfig style={{ marginTop: "50px" }} />
        </OktaProvider>
      </Router>
    </Provider>
  );
}

export default hot(App);

const LoginWithApi = () => {
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();
  const history = useHistory();

  useEffect(() => {
    if (authState?.isAuthenticated && authState?.accessToken) {
      Adminlogin({ token: authState?.accessToken.accessToken })
        .then(async () => {
          const data = await fetchDahboard(Cookies.get("site"));
          dispatch(setViewsResults(data?.viewsResults));
          dispatch(setWorkbooks(data?.workbooks));
          dispatch(apiDashboardCallSuccess(data));
          //  history.replace("/view/");
        })
        .finally(() => {
          history.replace("/");
        });
    }
  }, [authState?.accessToken, authState?.isAuthenticated, dispatch, history]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Spin size="large" />
    </div>
  );
};
