import React, { useContext } from "react";
import { Security } from "@okta/okta-react";

import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { useHistory } from "react-router-dom";

const Context = React.createContext();

function OktaProvider({ children }) {
  const history = useHistory();

  const clientId = process.env.REACT_APP_OKTA_CLIENT_ID;

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    const url = originalUri ?? "/redirect";
    history.push(toRelativeUrl(url, window.location.origin));
  };

  const oktaAuth = new OktaAuth({
    clientId,
    pkce: true,
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    redirectUri: window.location.origin + "/login/callback",
  });

  return (
    <Context.Provider
      value={{
        oktaAuth,
      }}
    >
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        {children}
      </Security>
    </Context.Provider>
  );
}

export default OktaProvider;

export const useOkta = () => useContext(Context);
