import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "../../../src/static/css/style.css";
import Cookies from "js-cookie";
import { Adminlogin, fetchDahboard } from "../../config/api/thunkapi";
import { useDispatch } from "react-redux";
import { setViewsResults, setWorkbooks } from "../../redux/sidebar/sidebar";
import {
  apiDashboardCallFailure,
  apiDashboardCallStart,
  apiDashboardCallSuccess,
} from "../../redux/dashboard/dashboardReducer";
import useWindowResize from "../../hooks/useWindowResize";
import { useOktaAuth } from "@okta/okta-react";

const Dashboard = () => {
  const [selectedViewUrl, setSelectedViewUrl] = useState("");
  const dispatch = useDispatch();

  const [selectedView, setSelectedView] = useState();
  const [selectedWorkBookName, setSelectedWorkBookName] = useState("");
  const viewResult = useSelector((state) => state.sidebar.viewsResults);
  const workbooks = useSelector((state) => state.sidebar.workbooks);
  const { id } = useParams();
  const size = useWindowResize();
  const { authState } = useOktaAuth();

  useEffect(() => {
    (async () => {
      try {
        if (authState?.accessToken.accessToken) {
          Adminlogin({ token: authState?.accessToken.accessToken });
        }
        if (Cookies.get("site") && viewResult?.length === 0) {
          dispatch(apiDashboardCallStart());
          const data = await fetchDahboard(Cookies.get("site"));
          dispatch(setViewsResults(data?.viewsResults));
          dispatch(setWorkbooks(data?.workbooks));
          dispatch(apiDashboardCallSuccess(data));
        }
      } catch (error) {
        dispatch(apiDashboardCallFailure());
      }
    })();
  }, []);

  useEffect(() => {
    if (id && viewResult.length > 0 && workbooks.length > 0) {
      let selectedView = null;
      let workbookId = "";
      let viewUrlName = "";

      for (const result of viewResult) {
        const hasView = result?.views?.find((view) => view.id === id);
        if (hasView) {
          workbookId = result.workbookId;
          viewUrlName = hasView.viewUrlName;
          selectedView = hasView;
          break;
        }
      }

      if (selectedView) {
        setSelectedView(selectedView);

        const selectedWorkBook = workbooks.find(
          (workbook) => workbook.id === workbookId
        );

        if (selectedWorkBook) {
          setSelectedWorkBookName(selectedWorkBook?.name);

          const baseUrl = selectedWorkBook.webpageUrl.substring(
            0,
            selectedWorkBook.webpageUrl.lastIndexOf("/workbooks/")
          );
          const viewUrl = `${baseUrl}/views/${selectedWorkBook.contentUrl}/${viewUrlName}`;
          setSelectedViewUrl(viewUrl);
        }
      }
    }
  }, [id, viewResult.length, workbooks.length]);

  return (
    <>
      <Main>
        <PageHeader
          ghost
          title={selectedWorkBookName}
          subTitle={selectedView?.name || ""}
        />
        <div
          style={{
            height: "calc(100vh - 64px)",
            // minHeight: "800px",
            width: "100%",
            // minWidth: "1400px",
          }}
        >
          {selectedViewUrl && (
            <tableau-viz
              id="tableauViz"
              key={size.height}
              className="tableau-viz"
              hide-tabs={false}
              touch-optimize={false}
              disable-url-actions={false}
              width="100%"
              height="100%"
              src={selectedViewUrl}
              toolbar="bottom"
              token={Cookies.get("embed_token")}
              style={{
                backgroundColor: "rgb(244, 245, 247)",
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </div>
      </Main>
    </>
  );
};

export default Dashboard;
