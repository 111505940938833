import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { toast } from 'react-toastify';
import { Container, Heading, SubHeading, StyledForm, ButtonWrapper } from './style'; 
import { submitContactForm } from '../../config/api/thunkapi';

const ContactUs = () => {
  const [form] = Form.useForm(); 
  const dispatch = useDispatch();
  
  const handleSubmit = async (values) => {
    const result = await dispatch(submitContactForm(values));
  
    if (result.success) {
      toast.success(result?.message || 'Your form has been submitted successfully!');
      form.resetFields();
    } else {
      toast.error(result.error);
    }
  };  

  return (
    <Container>
      <Heading>Contact Us</Heading>
      <SubHeading>Got a question? We'd love to hear from you. Send us a message and we'll respond as soon as possible.</SubHeading>
      <StyledForm form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please enter your name!' }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please enter your email!' }, { type: 'email', message: 'Please enter a valid email!' }]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>

        <Form.Item
          label="Message"
          name="message"
          rules={[{ required: true, message: 'Please enter your message!' }]}
        >
          <Input.TextArea placeholder="Enter your message" rows={4} />
        </Form.Item>

        <Form.Item>
            <ButtonWrapper>
                <Button type="primary" htmlType="submit">
                    Send
                </Button>
            </ButtonWrapper>
        </Form.Item>
      </StyledForm>
    </Container>
  );
};

export default ContactUs;
