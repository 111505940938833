

const userReducer = (state = [] ) => {
  return state;
};

const userGroupReducer = (state=[]) => {
  return state;
};

export { userReducer, userGroupReducer };
