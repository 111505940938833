// dataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    apiCallStart(state) {
      state.loading = true;
      state.error = null;
    },
    apiCallSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    apiCallFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { apiCallStart, apiCallSuccess, apiCallFailure } =
  dataSlice.actions;
export default dataSlice.reducer;
