import React, { useState } from "react";
import { Avatar } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { InfoWraper, NavAuth, UserDropDwon } from "./auth-info-style";
import { Popover } from "../../popup/popup";
import { logOut } from "../../../redux/authentication/actionCreator";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "js-cookie";
import { useOktaAuth } from "@okta/okta-react";

function AuthInfo() {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const [state, setState] = useState({
    flag: "english",
  });
  // const { flag } = state;

  const logout = async () => oktaAuth.signOut("/");

  const SignOut = (e) => {
    e.preventDefault();
    Cookies.remove("token");
    Cookies.remove("embed_token");
    Cookies.remove("site");
    history.push("/");
    dispatch(logOut());
    logout();
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/contact-us">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" to="#" onClick={SignOut}>
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value) => {
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle("english")} to="#">
        <img src={require("../../../static/img/flag/english.png")} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle("germany")} to="#">
        <img src={require("../../../static/img/flag/germany.png")} alt="" />
        <span>Germany</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle("spain")} to="#">
        <img src={require("../../../static/img/flag/spain.png")} alt="" />
        <span>Spain</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle("turky")} to="#">
        <img src={require("../../../static/img/flag/turky.png")} alt="" />
        <span>Turky</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
