import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    apiDashboardCallStart(state) {
      state.loading = true;
      state.error = null;
    },
    apiDashboardCallSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    apiDashboardCallFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { apiDashboardCallStart, apiDashboardCallSuccess, apiDashboardCallFailure } = dashboardSlice.actions;
export default dashboardSlice.reducer;
