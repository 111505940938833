import { createSlice } from '@reduxjs/toolkit';

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    viewsResults: [],
    workbooks: [],
    loading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setViewsResults: (state, action) => {
      state.viewsResults = action.payload;
    },
    setWorkbooks: (state, action) => {
      state.workbooks = action.payload;
    },
  },
});

export const { setLoading, setViewsResults, setWorkbooks } = sidebarSlice.actions;

export default sidebarSlice.reducer;
