import React, { useEffect, useState } from "react";
import { Menu, Spin } from "antd";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const { id } = useParams();

  const navigate = useHistory();
  const viewResult = useSelector((state) => state.sidebar.viewsResults);
  const workbooks = useSelector((state) => state.sidebar.workbooks);
  const loading = useSelector((state) => state.dashboard.loading);

  const matchedWorkbooks = workbooks.filter((workbook) =>
    viewResult.some((viewResult) => viewResult.workbookId === workbook.id)
  );

  const defaultOpenWorkbook =
    matchedWorkbooks.length > 0 ? matchedWorkbooks[0].id : null;
  const defaultFirstView =
    id ||
    (matchedWorkbooks.length > 0
      ? viewResult.find((view) => view.workbookId === defaultOpenWorkbook)
          ?.views[0]?.id
      : null);

  const [openKeys, setOpenKeys] = useState(
    !topMenu && defaultOpenWorkbook ? [defaultOpenWorkbook] : []
  );
  const [selectedKey, setSelectedKey] = useState(defaultFirstView || null);

  useEffect(() => {
    if (defaultFirstView) {
      setSelectedKey(defaultFirstView); // Set default selected key
      navigate.push(`/view/${defaultFirstView}`);
    }
  }, [defaultFirstView, navigate, path]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (openKeys.includes(latestOpenKey)) {
      setOpenKeys(openKeys.filter((key) => key !== latestOpenKey));
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onClick = (item) => {
    setSelectedKey(item.key); // Update selected key when clicked
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const getMenuContent = () => {
    if (loading) {
      return (
        <Menu.Item key="loading" disabled style={{ textAlign: "center" }}>
          <Spin size="small" />
        </Menu.Item>
      );
    } else if (matchedWorkbooks.length > 0) {
      return matchedWorkbooks.map((workbook) => {
        const workbookViews =
          viewResult.find((viewResult) => viewResult.workbookId === workbook.id)
            ?.views || [];
        return (
          <Menu.SubMenu key={workbook.id} title={workbook.name}>
            {workbookViews.map((view) => (
              <Menu.Item key={view.id}>
                <NavLink onClick={toggleCollapsed} to={`/view/${view.id}`}>
                  {view.name}
                </NavLink>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        );
      });
    }
    return (
      <Menu.Item key="no-data" disabled style={{ textAlign: "center" }}>
        No Data Available
      </Menu.Item>
    );
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      theme={darkMode ? "dark" : "light"}
      selectedKeys={[selectedKey]} // Highlight the selected item
      openKeys={openKeys}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
    >
      {getMenuContent()}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
