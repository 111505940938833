// rootReducer.js
import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { userReducer } from './users/reducers';
import sidebarReducer from './sidebar/sidebar';
import dataReducer from './users/dataReducer';
import dashboardReducer from './dashboard/dashboardReducer';

const appReducer = combineReducers({
  users: userReducer,
  auth: authReducer,
  sidebar: sidebarReducer,
  data: dataReducer,
  dashboard: dashboardReducer,
  ChangeLayoutMode,
});

// Handle logout globally
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
